const cognitoUsernameRegex = /^dormakaba-ID_[0-9]{5,10}$/;
const isValidCognitoUsernameForDokaId = (cgnUsername) => cognitoUsernameRegex.test(cgnUsername);

function castCgnUserToDormakabaId(cgnUsername) {
  if (isValidCognitoUsernameForDokaId(cgnUsername)) return cgnUsername.split('dormakaba-ID_')[1];
  return null
}


function checkCsr(csr){
    if(csr.match(/(-+BEGIN CERTIFICATE REQUEST-+)(.*?)(-+END CERTIFICATE REQUEST-+)/s)){
        return true;
    }
    return false;
}
export {checkCsr,isValidCognitoUsernameForDokaId,castCgnUserToDormakabaId}
